import { gql } from "graphql-tag";
import { CUSTOMER_FIELDS } from "./Fragments";

export const CREATE_CUSTOMER = gql`
	mutation CreateCustomer($input: CustomerInput!) {
		create_customer(customer: $input) {
			...CustomerFields
		}
	}
	${CUSTOMER_FIELDS}
`;

export const UPDATE_CUSTOMER = gql`
	mutation UpdateCustomer($input: CustomerInput!) {
		update_customer(customer: $input) {
			...CustomerFields
		}
	}
	${CUSTOMER_FIELDS}
`;

export const DELETE_CUSTOMER = gql`
	mutation DeleteCustomer($id: Int!) {
		delete_customer(id: $id)
	}
`;

export default { CREATE_CUSTOMER, UPDATE_CUSTOMER, DELETE_CUSTOMER };
