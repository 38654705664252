import { gql } from "graphql-tag";

export const CUSTOMER_GROUP_FIELDS = gql`
  fragment CustomerGroupFields on CustomerGroup {
    id
    name
    path
    created_at
  }
`;

export const PAGINATION = gql`
  fragment Pagination on CustomerGroupPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { CUSTOMER_GROUP_FIELDS, PAGINATION };