import { gql } from "graphql-tag";
import { CUSTOMER_GROUP_FIELDS, PAGINATION } from "./Fragments";
import { MANUFACTURER_FIELDS } from "@/modules/catalog/manufacturers/graphql/Fragments";

export const GET_CUSTOMER_GROUP = gql`
    query GetCustomerGroups($page: Int, $limit: Int) {
        customer_groups(limit:$limit, page:$page){
            ...Pagination
            data {
                ...CustomerGroupFields
            }
        }
    }
    ${CUSTOMER_GROUP_FIELDS},
    ${PAGINATION},
`;

export const GET_ECONOMIC_NUMBER = gql`
	query GetEconomicNumber($economic_number: String, $update: Boolean) {
		economic_number(economic_number: $economic_number, update: $update)
	}
`;

export const SEARCH_CUSTOMER_GROUPS = gql`
    query SearchCustomerGroups($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_customer_groups(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...CustomerGroupFields
            }
        }
    }
    ${CUSTOMER_GROUP_FIELDS},
    ${PAGINATION},
`;

export default { GET_CUSTOMER_GROUP, SEARCH_CUSTOMER_GROUPS, GET_ECONOMIC_NUMBER };
