import { gql } from "graphql-tag";

export const CUSTOMER_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    uuid
    economic_number
    parent {
      id
      name
      customer {
        id
        economic_number
      }
    }
    path
    user {
      id
      uuid
      name
      email
      status
      roles
      image {
        id
        file_path
      }
    }
    customer_group {
      id
      name
    }
    site {
      id
      name
      type
    }
  }
`;

export const GET_CUSTOMER_ORDERS = gql`
	fragment OrderFields on Order {
      id
      uuid
      economic_order_id
      economic_order_number
      order_status_id
      customer {
        user_id
        user {
          name
        } 
      }
      order_status {
        status
      }
      order_subscription_history{
            id
        }
      order_totals
      order_shipment {
        id
        order_id
      }
      created_at
	}
`;

export const CUSTOMER_INFO_DETAILS_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    uuid
    phone_number
    in_cup_agreement
    user {
      name
      email
      status
      country_id
      abilities
      roles
      country {
        id,
        name,
      }
    }
    customer_group {
      id
      name
    }
    site {
      type
      prices_with_tax
    }
    customer_to_shipping_methods {
      id
      shipping_method {
        uuid
        name
      }
    }
    cart {
      id
      quantity
      product_id
      created_at
      product {
        price_label
        tax_price_label
        tax_price
        description {
          name
        }
        media {
          file_path
        }
      }
    }
    cup_agreements {
      id
      description {
        name
      }
    }
  }
`;

export const CUSTOMER_USER_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    uuid
    user {
      name
      email
      status
      image {
        id
        file_path
      }
      created_at
    }
  }
`;

export const ACTIVE_CART_PRODUCTS_FIELDS = gql`
  fragment ActiveCartFields on Cart {
    id
    quantity
    product {
      description{
        name
      }
    }
    customer {
      user_id
      user {
        name
      }
    }
  }
`;

export const ACTIVE_CART_PAGINATION = gql`
  fragment ActiveCartPagination on ActiveCartPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;


export const PAGINATION = gql`
  fragment Pagination on CustomerPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export const PAGINATION_ORDER = gql`
  fragment Pagination on OrderPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { CUSTOMER_FIELDS, PAGINATION, GET_CUSTOMER_ORDERS, PAGINATION_ORDER, CUSTOMER_USER_FIELDS, CUSTOMER_INFO_DETAILS_FIELDS , ACTIVE_CART_PRODUCTS_FIELDS , ACTIVE_CART_PAGINATION };