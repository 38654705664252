
import { defineComponent, onMounted } from "vue";
import CustomersList from "../components/CustomersList.vue";
import { setPageHeader } from "@/core/helpers/toolbar";
export default defineComponent({
	components: {
		CustomersList,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.CUSTOMERS",
				actionButton: {
					ability: "add_customer",
					pageAction: {
						action: null,
					},
					button: {
						icon: "bi bi-plus",
						openModal: false,
						path: "/customer/add",
					},
				},
				breadCrumbs: [{ name: "message.HOME", link: "/dashboard" }, { name: "message.CUSTOMERS", link: "#" }, { name: "message.CUSTOMERS" }],
			});
		});
	},
});
